import { Box, Button, OutlinedInput } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Text from "components/Text/Typography";
import MessageItem from "components/Message/MessageItem";
import MessageInput from "components/Message/MessageInput";
import MessageModal from "components/Message/MessageModal";
import { v4 as uuidv4 } from "uuid";
import apis from "services/apis";
import { io } from "socket.io-client";
import ic_user_chat from "../../assets/images/ic_user_chat.png";
import axios from "axios";
import { useDispatch } from "react-redux";

import { showSnackbar } from "reducers/snackbarReducer";

var _ = require("lodash");

const URL = "https://socket.livechat24h.com";
const socket = io(URL);

const ChatUserPage = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);

  const [isStep, setIsStep] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [severMessages, setSeverMessages] = useState(null);

  const [avatar, setAvatar] = useState("");
  const [app, setApp] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const avatarUrl = params.get("avatar");
    if (avatarUrl) {
      setAvatar(avatarUrl);
    }

    const appUrl = params.get("app");
    if (appUrl) {
      setApp(appUrl);
      localStorage.setItem("app", appUrl);
    }

    loadStep();
  }, []);

  const loadStep = async () => {
    const storedUserId = localStorage.getItem("userID");
    const storeName = localStorage.getItem("name");
    if (storedUserId && storeName) {
      loadUUID();
      setIsStep(2);
    }
  };

  const getListMessage = async () => {
    try {
      const response = await axios.get(
        "https://api.livechat24h.com/user/message?limit=9999",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokenUser"),
          },
        }
      );

      if (response?.results?.datas) {
        setMessages(response?.results?.datas.reverse());
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // Khi socket kết nối thành công
    socket.on("connect", () => {
      console.log("Kết nối socket thành công:", socket.id);
    });

    // Khi socket ngắt kết nối
    socket.on("disconnect", () => {
      console.log("Socket bị ngắt kết nối");
    });

    socket.on("join-room", (event) => {
      console.log("join-room", event);
    });

    socket.on("connect_error", (error) => {
      console.log("Kết nối socket bị lỗi:", error);
    });

    socket.on("new-message", (event) => {
      setSeverMessages(event);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
    };
  }, []);

  useEffect(() => {
    const newChatMessage = {
      mimeType: severMessages?.mimeType,
      content:
        severMessages?.mimeType === "image"
          ? "https://cdn.livechat24h.com/" + severMessages?.content
          : severMessages?.content,
      createdAt: new Date(),
      position: "left",
    };
    setMessages([...messages, newChatMessage]);
  }, [severMessages]);

  const onJoinRoom = () => {
    const token = localStorage.getItem("tokenUser");
    const app = localStorage.getItem("app");
    const params = {
      token: token,
      app: app,
    };
    socket.emit("join-room", params);
  };

  const loadUUID = async () => {
    try {
      let storedUserId = localStorage.getItem("userID");
      let storeName = localStorage.getItem("name");
      let storeEmail = localStorage.getItem("email");
      const params = new URLSearchParams(window.location.search);
      const welcome = params.get("welcome");
      if (!storedUserId) {
        storedUserId = uuidv4();
        localStorage.setItem("userID", storedUserId);
      }

      if (storedUserId) {
        const params = {
          username: storedUserId,
          fullName: storeName,
          email: storeEmail,
          prompt: welcome ? welcome : "demo",
        };
        const response = await apis.loginUser(params);
        if (response?.results) {
          localStorage.setItem("tokenUser", response?.results?.access_token);
          onJoinRoom();
          getListMessage();
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    const newChatMessage = {
      mimeType: "text",
      content: newMessage,
      createdAt: new Date(),
      app: app,
      position: "right",
    };

    setMessages([...messages, newChatMessage]);
    setNewMessage("");
    inputRef.current?.focus();

    const params = {
      content: newMessage,
      mimeType: "text",
    };

    socket.emit("messages", params);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleUploadImage = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios(
          "https://api.livechat24h.com/public/upload",
          {
            method: "POST",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("response=", response);

        if (response?.results?.path) {
          const newChatMessage = {
            position: "right",
            mimeType: "image",
            content: response.results.path,
            date: new Date(),
          };
          setMessages([...messages, newChatMessage]);

          const params = {
            content: response.results.path,
            mimeType: "image",
          };
          socket.emit("messages", params);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setImage("");
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const onChangeStep = () => {
    if (!validateEmail(email)) {
      dispatch(showSnackbar("Email không hợp lệ"));
      return;
    }

    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    loadUUID();
    setIsStep(2);
  };

  const debouncedEmitTyping = _.debounce((message) => {
    socket.emit("message-typing", { content: message, mimeType: "text" });
  }, 500);

  const onChangeMessage = (message) => {
    setNewMessage(message);
    debouncedEmitTyping(message);
  };

  const _renderMessages = () => {
    return (
      <>
        {messages.map((message, index) => (
          <MessageItem
            key={index}
            position={
              message?.position
                ? message.position
                : message.sender === message.userId
                ? "right"
                : "left"
            }
            text={message.content}
            date={message.createdAt}
            avatar={avatar}
            setOpen={setOpen}
            setImage={setImage}
            mimeType={message.mimeType}
          />
        ))}
        <div ref={messagesEndRef} />
      </>
    );
  };

  const _renderPreviousChat = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          // height: 300,
          width: "100%",
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            height: 80,
            width: 80,
            backgroundColor: "#0066FF",
            borderRadius: 40,
            position: "absolute",
            left: "50%",
            top: 50,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img src={ic_user_chat} style={{ height: 50, width: 50 }} />
        </Box>
        <Box sx={{ marginTop: 8, paddingLeft: 2, paddingRight: 2 }}>
          <Text title="Welcome to our LiveChat! Please fill in the form below before starting the chat." />
          <Text title="Name: " style={{ marginTop: 10 }} />
          <OutlinedInput
            onChange={(event) => setName(event.target.value)}
            value={name}
            fullWidth
            placeholder="abc1234"
            sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          />
          <Text title="Email: " style={{ marginTop: 10 }} />
          <OutlinedInput
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            fullWidth
            placeholder="abc@gmail.com"
            sx={{ borderRadius: 2, width: "100%", height: 50, marginTop: 1 }}
          />
          <Button
            onClick={onChangeStep}
            fullWidth
            variant="contained"
            style={{
              height: 55,
              borderRadius: 8,
              marginTop: 15,
              backgroundColor: "#1E68D7",
              color: "#FEFEFE",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "500",
              textTransform: "none",
              opacity: !name || !email ? 0.5 : 1,
            }}
            disabled={name === "" || email === ""}
          >
            Start the chat
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      {isStep === 1 ? (
        _renderPreviousChat()
      ) : (
        <div>
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: "#f5f5f5",
              overflowY: "auto",
              padding: 2,
              marginTop: "0px",
              marginBottom: "56px",
            }}
          >
            {_renderMessages()}
          </Box>
          <MessageInput
            inputRef={inputRef}
            sendMessage={handleSendMessage}
            message={newMessage}
            setMessage={onChangeMessage}
            sendImage={handleUploadImage}
          />
        </div>
      )}
      <MessageModal open={open} image={image} onClose={handleCloseModal} />
    </Box>
  );
};

export default ChatUserPage;
