// SocketContext.js
import React, { createContext } from "react";
import { io } from "socket.io-client";

const URL = "https://socket.livechat24h.com";
const socket = io(URL);

export const SocketContext = createContext(socket);

export const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
