import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import LoginPage from "./pages/authentication/Login";

import { useDispatch } from "react-redux";
import { network } from "services";

import { useTranslation } from "react-i18next";
import ListChatPage from "pages/chat/ListChat";
import ChatDetailPage from "pages/chat/ChatDetail";
import ChatUserPage from "pages/chat/ChatUser";
import { SocketProvider } from "services/SocketContext";
const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      network.setToken(accessToken);
      navigate("/main");
    }
    getLocalLanguage();
  }, []);

  const getLocalLanguage = () => {
    const language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language);
    }
  };

  return (
    <SocketProvider>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/main" element={<ListChatPage />} />
        <Route path="/main/chat/:id" element={<ChatDetailPage />} />
        <Route path="/chat-user" element={<ChatUserPage />} />
      </Routes>
    </SocketProvider>
  );
};

export default App;
