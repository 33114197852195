import { Box, Avatar, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import Text from "components/Text/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const MessageItem = ({
  position,
  text,
  date,
  avatar,
  setOpen,
  setImage,
  mimeType,
  onEdit,
  onDelete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(text);
  const [showIcons, setShowIcons] = useState(false); // State to control icon visibility

  const formattedImageUrl =
    text && !/^https?:\/\//i.test(text)
      ? `https://cdn.livechat24h.com/${text}`
      : text;

  const handleEditSave = () => {
    onEdit(editContent);
    setIsEditing(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: position === "right" ? "row-reverse" : "row",
        alignItems: "center",
        marginBottom: 2,
      }}
      onMouseEnter={() => setShowIcons(true)} // Show icons on mouse enter
      onMouseLeave={() => setShowIcons(false)} // Hide icons on mouse leave
    >
      {position === "left" && (
        <Avatar
          src={avatar}
          sx={{
            marginRight: position === "right" ? 0 : 2,
            marginLeft: position === "right" ? 2 : 0,
          }}
        />
      )}
      <Box
        sx={{
          backgroundColor: position === "right" ? "#1E68D7" : "#ECEAEA",
          padding: 2,
          borderRadius: 2,
          maxWidth: "70%",
          boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
          position: "relative",
        }}
      >
        {mimeType === "image" && (
          <img
            src={formattedImageUrl}
            alt="new"
            style={{ height: 200, width: 200 }}
            onClick={() => {
              setOpen(true);
              setImage(formattedImageUrl);
            }}
          />
        )}

        {mimeType !== "image" &&
          (isEditing ? (
            <TextField
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
              onBlur={handleEditSave}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleEditSave();
              }}
              size="small"
              autoFocus
            />
          ) : (
            <Text
              style={{
                color: position === "right" ? "#FFFFFF" : "#1E1E1E",
                fontSize: 14,
                whiteSpace: "pre-line",
              }}
              title={text}
              onClick={() => setIsEditing(true)}
            >
              {text}
            </Text>
          ))}

        <Text
          style={{
            color: position === "right" ? "#FFFFFF" : "#1E1E1E",
            fontSize: 12,
            display: "block",
            textAlign: position === "right" ? "right" : "left",
          }}
          title={moment(date).format("HH:mm")}
        />

        {/* Nút xóa và chỉnh sửa */}
        {showIcons && (
          <Box
            sx={{
              position: "absolute",
              top: -5,
              right: position === "right" ? "100%" : "auto",
              left: position === "right" ? "auto" : "100%",
              display: "flex",
              gap: 1,
            }}
          >
            <IconButton
              size="small"
              onClick={() => setIsEditing(true)}
              sx={{ color: position === "right" ? "#1E68D7" : "#1E1E1E" }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={onDelete}
              sx={{ color: position === "right" ? "#1E68D7" : "#1E1E1E" }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MessageItem;
