import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ic_showpassword from "../../assets/images/ic_showpassword.png";
import ic_hidepassword from "../../assets/images/ic_hidepassword.png";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducers/snackbarReducer";
import apis from "services/apis";
import { network } from "services";
import { startLoading, stopLoading } from "reducers/loadingReducer";
import { setProfile } from "./reducer";
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSaveAccount, setIsSaveAccount] = useState(false);

  useEffect(() => {
    getLocal();
  }, []);

  const getLocal = () => {
    if (localStorage.getItem("password")) {
      setPassword(localStorage.getItem("password"));
      setIsSaveAccount(true);
    }

    if (localStorage.getItem("username")) {
      setEmail(localStorage.getItem("username"));
      setIsSaveAccount(true);
    }
  };

  const handleLogin = () => {
    if (!email) {
      dispatch(showSnackbar("Vui lòng nhập username"));
      return;
    }

    if (!password) {
      dispatch(showSnackbar("Vui lòng nhập password"));
      return;
    }

    onSubmitLogin();
  };

  const onSubmitLogin = async () => {
    try {
      dispatch(startLoading());
      let params = {
        password: password,
        username: email,
      };

      const response = await apis.login(params);
      if (response?.results) {
        localStorage.setItem("token", response?.results?.access_token);
        network.setToken(response?.results?.access_token);
        if (isSaveAccount) {
          localStorage.setItem("username", email);
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
        }
        dispatch(stopLoading());
        navigate("/main");

        // onGetProfile();
      }
    } catch (error) {
      dispatch(stopLoading());
      dispatch(showSnackbar("Đăng nhập thất bại"));
      console.log("error", error);
    }
  };

  const onGetProfile = async () => {
    try {
      dispatch(stopLoading());
      const response = await apis.getProfile();
      if (response?.results) {
        dispatch(setProfile(response?.results));
        localStorage.setItem("profile", JSON.stringify(response?.results));
        navigate("/main/home");
      }
    } catch (error) {
      dispatch(stopLoading());
      console.log("error", error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {};

  const onSaveAccount = (value) => {
    setIsSaveAccount(!isSaveAccount);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginTop: 3.5,
            color: "#0A0A0A",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {t("login").toUpperCase()}
        </Typography>

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <>
            <Typography
              component="p"
              variant="body2"
              sx={{
                marginBottom: 1,
                color: "#0A0A0A",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Username
            </Typography>
            <OutlinedInput
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
              placeholder="abc1234"
              sx={{ borderRadius: 2, width: "100%", height: 50 }}
            />
          </>

          <Typography
            component="p"
            variant="body2"
            sx={{
              marginBottom: 1,
              marginTop: 2.5,
              color: "#0A0A0A",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Password
          </Typography>
          <OutlinedInput
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            placeholder={t("holderPassword")}
            type={showPassword ? "text" : "password"}
            sx={{ borderRadius: 2, width: "100%", height: 50 }}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <img
                    src={ic_hidepassword}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                ) : (
                  <img
                    src={ic_showpassword}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                )}
              </IconButton>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                checked={isSaveAccount}
                color="primary"
                onChange={(e) => onSaveAccount(e.target.checked)}
              />
            }
            label={t("rememberMe")}
            style={{
              justifyContent: "flex-end",
              width: "100%",
              color: "#0A0A0A",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          />
          <Button
            onClick={handleLogin}
            fullWidth
            variant="contained"
            style={{
              height: 45,
              borderRadius: 4,
              marginTop: 10,
              backgroundColor: "#1E68D7",
              color: "#FEFEFE",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {t("login")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
