import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationVI from "./locales/vi/translation.json";
import translationCN from "./locales/cn/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationTH from "./locales/th/translation.json";
const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
  cn: {
    translation: translationCN,
  },
  ja: {
    translation: translationJA,
  },
  pt: {
    translation: translationPT,
  },
  th: {
    translation: translationTH,
  },
};

i18n
  .use(LanguageDetector) // Xác định ngôn ngữ người dùng
  .use(initReactI18next) // Chuyển đổi sang hook của react-i18next
  .init({
    resources,
    fallbackLng: "vi", // Ngôn ngữ dự phòng
    lng: "vi", // Ngôn ngữ mặc định
    interpolation: {
      escapeValue: false, // React đã bảo vệ khỏi XSS
    },
  });

export default i18n;
