import { Box, IconButton } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ic_back from "../../assets/images/ic_back.png";
import Text from "components/Text/Typography";
import MessageItem from "components/Message/MessageItem";
import MessageInput from "components/Message/MessageInput";
import MessageModal from "components/Message/MessageModal";
import apis from "services/apis";
import { SocketContext } from "services/SocketContext"; // Import đúng context
import ic_writing from "../../assets/images/ic_writing.png";
import axios from "axios";
const ChatDetailPage = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const socket = useContext(SocketContext);

  const [severMessages, setSeverMessages] = useState(null);
  const [messageTyping, setMessageTyping] = useState(null);
  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    onRead();
    getMessage();
  }, []);

  const onRead = async () => {
    try {
      await apis.markRead(id);
    } catch (error) {
      console.log(error);
    }
  };

  const getMessage = async () => {
    try {
      const response = await apis.getDetailChat(id);
      if (response?.results?.datas) {
        setMessages(response?.results?.datas.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onJoinRoom();

    socket.on("join-admin", (event) => {
      console.log("join-admin", event);
    });

    socket.on("messages", (event) => {
      console.log("Messages", event);
    });

    socket.on("new-message", (event) => {
      setSeverMessages(event);
      setMessageTyping(null);
    });

    socket.on("message-typing", (event) => {
      console.log("message-typing", event);
      setMessageTyping(event);
    });
  }, []);

  useEffect(() => {
    const newChatMessage = {
      mimeType: severMessages?.mimeType,
      content: severMessages?.content,
      createdAt: new Date(),
      position: "left",
    };
    setMessages([...messages, newChatMessage]);
  }, [severMessages]);

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    const newChatMessage = {
      mimeType: "text",
      content: newMessage,
      createdAt: new Date(),
      position: "right",
    };

    setMessages([...messages, newChatMessage]);
    setNewMessage("");
    inputRef.current?.focus();

    const params = {
      content: newMessage,
      mimeType: "text",
      user: id,
    };

    socket.emit("messages", params);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleUploadImage = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios(
          "https://api.livechat24h.com/public/upload",
          {
            method: "POST",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("response=", response);

        if (response?.results?.path) {
          const newChatMessage = {
            position: "right",
            mimeType: "image",
            content: response.results.path,
            date: new Date(),
          };
          setMessages([...messages, newChatMessage]);

          const params = {
            content: response.results.path,
            mimeType: "image",
            user: id,
          };
          socket.emit("messages", params);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setImage("");
  };

  const onJoinRoom = () => {
    const token = localStorage.getItem("token");
    const params = {
      token: token,
      user: id,
    };
    socket.emit("join-admin", params);
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await apis.removeItemChat(messageId);
      if (response?.message === "success") {
        const newMessages = messages.filter(
          (message) => message._id !== messageId
        );
        setMessages(newMessages);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleEditMessage = async (messageId, content) => {
    try {
      const response = await apis.editMessage(messageId, { content });
      if (response?.message === "success") {
        // Tạo mảng tin nhắn mới với nội dung đã cập nhật
        const newMessages = messages.map((message) => {
          if (message._id === messageId) {
            return {
              ...message,
              content, // Cập nhật nội dung tin nhắn
            };
          }
          return message;
        });

        // Kiểm tra xem newMessages đã cập nhật đúng chưa
        console.log("Updated Messages:", newMessages);

        // Cập nhật state với mảng tin nhắn mới
        setMessages(newMessages);
      } else {
        console.log("Edit message failed:", response);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const _renderMessages = () => {
    return (
      <>
        {messages.map((message, index) => (
          <MessageItem
            key={index}
            position={
              message?.position
                ? message.position
                : message.sender === message.userId
                ? "left"
                : "right"
            }
            text={message.content}
            date={message.createdAt}
            avatar={message.avatar}
            setOpen={setOpen}
            setImage={setImage}
            messageTyping={messageTyping}
            mimeType={message.mimeType}
            onDelete={() => handleDeleteMessage(message._id)}
            onEdit={(content) => handleEditMessage(message._id, content)}
          />
        ))}
        <div ref={messagesEndRef} />
      </>
    );
  };

  const _renderHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: "#FFFFFF",
          zIndex: 1, // Đảm bảo header luôn ở phía trên nội dung
        }}
      >
        <IconButton onClick={handleBackClick}>
          <img src={ic_back} style={{ height: 32, width: 32 }} />
        </IconButton>
        <Text
          style={{
            color: "#1E68D7",
            fontSize: 20,
            textAlign: "center",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            fontWeight: "bold",
          }}
          title={"Khách hàng"}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      {_renderHeader()}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "#f5f5f5",
          overflowY: "auto",
          padding: 2,
          marginTop: "45px", // Tránh trùng với header cố định
          marginBottom: "56px", // Tránh trùng với input box cố định
        }}
      >
        {_renderMessages()}

        {messageTyping && messageTyping.content && (
          <Box
            sx={{
              backgroundColor: "#ECEAEA",
              padding: 1.5,
              borderRadius: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={ic_writing} style={{ height: 20, width: 20 }} />
            <Text
              title={messageTyping?.content}
              style={{ color: "#1E1E1E", fontSize: 14 }}
            />
          </Box>
        )}
      </Box>
      <MessageInput
        inputRef={inputRef}
        sendMessage={handleSendMessage}
        message={newMessage}
        setMessage={setNewMessage}
        sendImage={handleUploadImage}
      />
      <MessageModal open={open} image={image} onClose={handleCloseModal} />
    </Box>
  );
};

export default ChatDetailPage;
